import React, { useEffect, useState } from 'react';

import { Spinner } from 'components/common';
import ChecksumTypes from 'archive/logic/ChecksumTypes';
import CompressionTypes from 'archive/logic/CompressionTypes';
import NumberUtils from 'util/NumberUtils';
import { ArchiveConfigurationActions } from 'archive/ArchiveConfigurationStore';
import { ArchiveBackendsActions } from 'archive/ArchiveBackendsStore';
import { Alert } from 'components/bootstrap';

import { StyledDescriptionWrapper } from './StyledCatalogComponents';

import type { Backend, ArchiveConfiguration } from '../types';

const ArchiveConfig = () => {
  const timerInterval = 5000;
  const [currentConfig, setCurrentConfig] = useState<ArchiveConfiguration | typeof undefined>();
  const [currentBackend, setCurrentBackend] = useState<Backend | typeof undefined>();

  useEffect(() => {
    const setConfigAndBackend = (configuration: ArchiveConfiguration) => {
      setCurrentConfig(configuration);

      if (configuration.backend_id) {
        ArchiveBackendsActions.getBackend(configuration.backend_id).then((backend) => {
          setCurrentBackend(backend);
        });
      }
    };

    ArchiveConfigurationActions.getConfig().then(setConfigAndBackend);

    let timerId = setInterval(() => {
      ArchiveConfigurationActions.getConfig().then(setConfigAndBackend);
    }, timerInterval);

    return () => {
      clearInterval(timerId);
      timerId = undefined;
    };
  }, []);

  if (!currentConfig || (currentConfig?.backend_id && !currentBackend)) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>Configuration</h2>
      <StyledDescriptionWrapper marginLeft={200}>
        {!currentBackend && <Alert bsStyle="danger">No backend configured. Archiving is not properly set up!</Alert>}
        <dl className="archive-config-overview">
          <dt>Backend:</dt>
          <dd>{currentBackend ? `${currentBackend.title} - ${currentBackend.settings.output_path}` : <em>No backend configured</em>}</dd>
          <dt>Max segment size:</dt>
          <dd>{NumberUtils.formatBytes(currentConfig.max_segment_size)}</dd>
          <dt>Compression type:</dt>
          <dd>{CompressionTypes.getCompressionType(currentConfig.segment_compression_type).label}</dd>
          <dt>Checksum type:</dt>
          <dd>{ChecksumTypes.getChecksumType(currentConfig.segment_checksum_type).label}</dd>
          <dt>Restore index batch size:</dt>
          <dd>{NumberUtils.formatNumber(currentConfig.restore_index_batch_size)}</dd>
          <dt>Failure notification threshold:</dt>
          <dd>{NumberUtils.formatNumber(currentConfig.archive_failure_threshold)}</dd>
        </dl>
      </StyledDescriptionWrapper>
    </div>
  );
};

export default ArchiveConfig;
