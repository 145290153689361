import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import naturalSort from 'javascript-natural-sort';

import { ButtonGroup, Button, ControlLabel, FormGroup, HelpBlock, Input, Alert } from 'components/bootstrap';
import { Icon } from 'components/common';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';

const StyledAlert = styled(Alert)`
  overflow: auto;
  margin-right: 15px;
  margin-left: 15px;
`;

class ArchiveRetentionStrategyConfiguration extends React.Component {
  constructor(props) {
    super(props);
    const { config: { max_number_of_indices, index_action } } = this.props;

    this.state = {
      max_number_of_indices: max_number_of_indices,
      index_action: index_action,
    };
  }

  _onInputUpdate = (field) => {
    return (e) => {
      const update = { index_action: this.state.index_action };
      const { updateConfig } = this.props;
      update[field] = e.target.value;

      this.setState(update);
      updateConfig(update);
    };
  };

  _setIndexAction = (type) => {
    const { updateConfig } = this.props;
    const { max_number_of_indices } = this.state;

    return () => {
      this.setState({ index_action: type });
      updateConfig({ index_action: type, max_number_of_indices: max_number_of_indices });
    };
  };

  _indexActionActive = (type) => {
    return this.state.index_action === type;
  };

  _indexActions = () => {
    const indexActions = this.props.jsonSchema.properties.index_action.enum;

    indexActions.sort((a, b) => naturalSort(a.toLowerCase(), b.toLowerCase()));

    return indexActions;
  };

  render() {
    // Get all available index actions from the JSON schema.
    const buttons = this._indexActions().map((action) => {
      const isActive = this._indexActionActive(action);

      return (
        <Button key={`button-${action}`}
                onClick={this._setIndexAction(action)}
                active={isActive}
                bsStyle={isActive ? 'info' : 'default'}>
          {action}
        </Button>
      );
    });

    return (
      <div>
        <ArchiveLicenseStatusContainer summary />
        <StyledAlert>
          <Icon name="info-circle" />{' '} The archive index retention strategy creates an archive for an index before deleting or closing it.
        </StyledAlert>
        <fieldset>
          <Input type="number"
                 id="max-number-of-indices"
                 label="Max number of indices"
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9"
                 onChange={this._onInputUpdate('max_number_of_indices')}
                 value={this.state.max_number_of_indices}
                 help={<span>Maximum number of indices to keep before <strong>archiving</strong> the oldest ones</span>}
                 autoFocus
                 required />
        </fieldset>
        <FormGroup id="action-after-archive">
          <ControlLabel className="col-sm-3">Action after archiving index</ControlLabel>
          <div className="col-sm-9">
            <ButtonGroup bsSize="small">{buttons}</ButtonGroup>
            <HelpBlock>Please select which action should be performed once an index has been archived.</HelpBlock>
          </div>
        </FormGroup>
      </div>
    );
  }
}

ArchiveRetentionStrategyConfiguration.propTypes = {
  config: PropTypes.object.isRequired,
  jsonSchema: PropTypes.object.isRequired,
  updateConfig: PropTypes.func.isRequired,
};

export default ArchiveRetentionStrategyConfiguration;
