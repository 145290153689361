import React, { useEffect } from 'react';
import connect from 'stores/connect';
import { LinkContainer } from 'components/common/router';
import { Button, Row, Col } from 'components/bootstrap';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import Routes from 'routing/Routes';
import AppConfig from 'util/AppConfig';
import { Store } from 'stores/StoreTypes';

import ArchiveBackends from 'archive/components/ArchiveBackends';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';
import { DEFAULT_PAGE_SIZE } from 'archive/ArchiveStore';
import ArchiveBackendsStore, { ArchiveBackendsActions, ArchiveBackendsStoreState } from 'archive/ArchiveBackendsStore';
import type { Backend, Pagination } from 'archive/types';
import ArchivePageHeaderSupport from 'archive/components/ArchivePageHeaderSupport';
import { BackendsPropType } from 'archive/propTypes';

const isCloud = AppConfig.isCloud();

type Props = {
  backends: {
    backends: Array<Backend>,
    pagination: Pagination,
  },
};

const ArchiveBackendsPage = ({ backends }: Props) => {
  useEffect(() => {
    ArchiveBackendsActions.listBackends(1, DEFAULT_PAGE_SIZE);
  }, []);

  const _onDelete = (backend: Backend, deleteArchives: boolean) => {
    ArchiveBackendsActions.deleteBackend(backend, deleteArchives);
  };

  const _onPageChange = (newPage: number, pageSize: number) => {
    ArchiveBackendsActions.listBackends(newPage, pageSize);
  };

  if (!backends) {
    return <Spinner text="Loading backends" />;
  }

  return (
    <DocumentTitle title="Archive backend configuration">
      <div>
        <PageHeader title="Edit archive backend configuration">
          <span>
            The Graylog archive feature allows you to create archives from indices. The generated archives
            are simple flat files that can be moved to cheap storage and re-imported at any time.
          </span>
          <ArchivePageHeaderSupport />
          <span>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
              <Button bsStyle="info">Overview</Button>
            </LinkContainer>
              &nbsp;
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
              <Button bsStyle="info">Configuration</Button>
            </LinkContainer>
              &nbsp;
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
              <Button bsStyle="info" className="active">Manage Backends</Button>
            </LinkContainer>
          </span>
        </PageHeader>
        <ArchiveLicenseStatusContainer />
        <Row className="content archive-backends">
          <Col lg={12}>
            {!isCloud && (
              <div className="pull-right">
                <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS_NEW')}>
                  <Button bsStyle="success">Create Backend</Button>
                </LinkContainer>
              </div>
            )}
            <ArchiveBackends backends={backends.backends}
                             pagination={backends.pagination}
                             onDelete={_onDelete}
                             onPageChange={_onPageChange} />
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

ArchiveBackendsPage.propTypes = {
  backends: BackendsPropType,
};

ArchiveBackendsPage.defaultProps = {
  backends: undefined,
};

export default connect(ArchiveBackendsPage,
  { archiveBackends: ArchiveBackendsStore as Store<ArchiveBackendsStoreState> },
  ({ archiveBackends, ...otherProps }) => ({
    backends: archiveBackends.backends,
    ...otherProps,
  }));
