import React from 'react';
import { Link } from 'components/common/router';
import Routes from 'routing/Routes';
import AppConfig from 'util/AppConfig';

const isCloud = AppConfig.isCloud();

const ArchivePageHeaderSupport = () => {
  return (
    <>
      {isCloud ? (
        <span>
          Old indices are automatically archived with default index retention strategy settings.
        </span>
      ) : (
        <span>
          Archive your old indices automatically by setting your index retention strategy to archive
          on the{' '} <Link to={Routes.SYSTEM.INDICES.LIST}>indices</Link>{' '}
          page.
        </span>
      )}
    </>
  );
};

export default ArchivePageHeaderSupport;
